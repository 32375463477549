<template>
    <v-row no-gutters>
        <v-col class="main-col" lg="7">
            <v-container class="pa-4 pa-sm-12 pt-sm-8">
                <v-card width="600px" elevation="0" rounded class="pa-4 pa-sm-6">
                    <img class="mb-10" style="width: 150px;" width="232px;" :src="require(`@/assets/svg/voiceform_full_black.svg`)"/>
                    <div class="text-h5 text-md-h4 mb-6">
                        Reset your password
                    </div>
                    <v-form 
                        ref="form" 
                        v-model="valid"
                        lazy-validation
                        @submit.prevent="handleForgotPassword"
                    >
                        <div class="my-5 text-body-2 grey--text text--darken-1">Enter the email address associated with your account and we'll send you a link to reset your password.</div>
                        <div class="text-subtitle-1 mb-4">Email Address</div>
                        <v-text-field 
                            hide-details="auto" 
                            label="Email" 
                            v-model="form.email"
                            class="mb-4"
                            outlined
                            @keyup.enter.prevent
                            @keyup.enter="handleEnter"
                            :rules="emailRules"
                            validate-on-blur
                            :error-messages="this.errors.email"
                        ></v-text-field>
                        <v-btn
                            class="mt-5"
                            color="primary"
                            @click="handleForgotPassword"
                            @click.prevent
                            large
                            elevation="0"
                            :loading="loading"
                            :width="$vuetify.breakpoint.xs? '100%': '300px'"
                        >
                            Continue
                        </v-btn>
                        <div class="mt-5 text-body-2 grey--text text--darken-1"><router-link :to="{name: 'login'}">Return to login</router-link></div>
                    </v-form>
                </v-card>
            </v-container>
        </v-col>
        <v-col lg="5" class="d-none d-lg-block sub-col pa-10">
            <div class="ma-auto">
                <img class="mt-10 illustration" style="width: 100%" width="487px" :src="require(`@/assets/svg/illustration.svg`)"/>
            </div>
        </v-col>
    </v-row>
    <!-- TODO: Add voiceform trademark and contact info links at bottom of login, signup, reset and forgot password pages -->
</template>

<script>
import Api from "../api/Api"
import { mapMutations } from "vuex";

export default {
    name: "ForgotPassword",
    data() {
        return {
            valid: true,
            loading: false,
            form: {
                email: null,
            },
            errors: {},
            emailRules: [
                value => !!value || 'Required',
            ],
        };
    },
    watch: {
        'form.email': function (){
            if(this.errors && this.errors.email){
                this.errors.email = null
            }
        },
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        async handleForgotPassword() {
            const valid = this.$refs.form.validate();
            if(!valid) {
                return;
            }
            this.loading = true;
            const { email } = this.form;
            let response = await Api.forgotPassword(
                email,
            ).catch((e) => {
                this.loading = false;
                this.errors = e.response.data.errors
            });
            if (response && response.status === 200) {
                this.loading = false;
                this.errors = {};
                this.showSnackBar({
                    color: "success",
                    timeout: 6000,
                    text: "Thanks, check your email for instructions to reset your password"
                })
            }
        }
    },
};
</script>

<style lang="scss">
    :root {
        --overflow: hidden;
    }
</style>

<style scoped lang="scss">
    .sub-col {
        background-color: var(--v-primary-lighten5);
        overflow: hidden;
    }
    .main-col{
        position: relative;
        box-shadow: -20px 0px 30px 75px rgba(0, 0, 0, 0.05);
        height: 100vh;
        overflow: scroll;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }
    .main-col::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
    .illustration{
        margin-left: -100px;
    }
</style>
